.choose-game, .choose-center{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
}
.choose-title-text{
    font-weight: bold;
    text-decoration: underline solid black;
    font-size: 6vh;
    font-weight: bold;
    margin-top: 2.5%;
    margin-bottom: 1.5%;
    color: white;
}
.choose-logo{
    width: 40%;
    max-width: 350px;
    margin-top: 2.5%;
}
.choose-game-list {
    display: flex;
    flex-direction: column;
    margin:0 auto;
    height: 100%;
    padding: 0;
    background-color: black;
    color: white;
    min-width: auto;
    width: 55%;
    max-width: 400px;
    text-align: center;
    font-weight: 100;
    overflow: scroll;
    margin-top: 2.5%;
    margin-bottom: 10%;
}

.choose-game-category-title {
    list-style: none;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    border: 1px rgb(82, 52, 13) solid;
    width: 98%;
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-size: 125%;
    padding-top: 5%;
    padding-bottom: 5%;
}
.choose-game-category-title:hover{
    background-color: cadetblue;
    font-weight: 800;
}