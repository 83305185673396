html, body, #root {
  margin:  0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app  {
  display: inline-block;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.app-menu {
  width: 33%;
  height: 100%;
  float: left;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  text-align: center; 
}

.app-body {
  width: 67%;
  height: 100%;
  float: left;
}

.vertical-center {
  display: flex; 
  flex-direction: column;
  text-align: center;
  background-color: black;
  align-items: center;
  justify-content: center;
  width:100%;
  height:100%;
}

/**** THESE TWO BLOCKS GOT THE CATEGORY LIST TO SCROLL WITHOUT SHOWING A BAR *****/
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.title-text {
  font-weight: bold;
  text-decoration: underline solid white;
  color: white;
  margin-top: 0%;
  align-items: center;
  padding-top: 1em;
}
