.menu {
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: 100%;
  float: left;
  display: flex;

  text-align: center;
  background-color: black;
  align-items: center;
  justify-content: center;

  background-color: black;
  font-size: calc(10px + 2vmin);
  color: white;

  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Safari */
  overflow: auto;
}
.menu-center {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: black;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}
#mt1 {
  font-weight: bold;
  text-decoration: underline solid black;
  font-size: 200%;
  margin: 0;
  color: lightgrey;
}
.menu-logo {
  width: 40%;
  max-width: 350px;
  pointer-events: none;
  border-style: solid;
  border-color: black;
}
#mt2 {
  font-weight: bold;
  font-size: 105%;
  margin: 0 5%;
  color: lightgrey;
}
.menu-button {
  display: flex;
  justify-content: space-around;
  border-radius: 8px;
  text-decoration: underline solid black;
  background-color: black;
  color: lightgrey;
  min-width: auto;
  width: 45%;
  max-width: 350px;
}
.menu-button:hover {
  background-color: cadetblue;
  font-weight: 800;
  text-decoration: underline solid cadetblue;
}
