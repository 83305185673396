a:link {
    text-decoration: none;
}
 a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
a:active {
    text-decoration: none;
}

/** Page Wrap **/
.create-game-wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;
}
/** Page Header **/
.create-game-header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
}
.create-title-text{
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
    color: lightgrey;
    font-size: 6vh;
    font-weight: bold;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
}
.create-game-logo{
    margin: auto;
    border-style: solid;
    border-color: black;
    max-width: 35%;
    max-height: 20vh;
    margin-bottom: 2.5%;
}
/** Form Fields **/
.create-form-wrap{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.create-title {
    color: lightgrey;
    font-size: 4vh;
    margin-bottom:0;
    margin-top:0;
    max-height: 20vh;
    text-align: center;
}
.form-wrap{
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
}
.form-input{
    height: 4vh;
    font-size: 150%;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
}
.form-submit-link{
    width: 100%;
    display: flex;
    align-items: center;
}
.form-submit{
    display: flex;
    height: 3.5vh;
    font-size: 125%;
    font-weight: 600;
    width: 75%;
    margin: 2.5% auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
}
